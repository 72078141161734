import React, { useRef, useEffect, useState, useCallback } from "react";
import SVG from "react-inlinesvg";
import C from "classnames";

import style from "./index.module.styl";

interface IDropSelectProps {
  readonly title: string;
  readonly expandFunction: any;
  readonly expandProps: any;
  readonly openFromOutside?: boolean;
  readonly openStateListener?: (open: boolean) => void;
}

export default function DropSelect({
  title,
  expandFunction,
  expandProps,
  openFromOutside,
  openStateListener,
}: IDropSelectProps) {
  const [open, setOpen] = useState<boolean>(false);
  const dropSelectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickedOutside = (event: MouseEvent) => {
      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      if (
        !dropSelectRef.current ||
        !dropSelectRef.current.contains(event.target)
      ) {
        handleClickEvent(false);
      }
    };
    document.addEventListener("mousedown", handleClickedOutside);

    return () =>
      document.removeEventListener("mousedown", handleClickedOutside);
  }, []);

  useEffect(() => {
    if (openFromOutside === undefined) {
      return;
    }
    setOpen(openFromOutside);
  }, [openFromOutside]);

  const handleClickEvent = (state: boolean) => {
    if (!!openStateListener) {
      openStateListener(state);
    } else {
      setOpen(state);
    }
  };

  const Expand = expandFunction;

  const props = { ...expandProps };
  if (!open) props.tabIndex = "-1";

  return (
    <div ref={dropSelectRef}>
      <div
        role="button"
        onClick={() => handleClickEvent(!open)}
        className={C(style.wrapper, { [style.open]: open })}
      >
        <div className={style.header}>
          <div className={style.headerText}>{title}</div>
          <div className={style.headerIconWrapper}>
            <SVG
              src={require("../../../images/rounded-triangle.svg")}
              className={style.headerIcon}
            />
          </div>
        </div>
      </div>
      <div className={C(style.expand, { [style.open]: open })}>
        <Expand {...props} />
      </div>
    </div>
  );
}
