import React from "react";
import style from "./index.module.styl";
import { useGlobalStore } from "../../store";

export interface IDeliveryDescription {
  handling: string;
  packaging: string;
}

interface IProps {
  readonly labelDescription: IDeliveryDescription;
  readonly isForPresentation?: boolean;
}

const DeliveryDescription = ({
  labelDescription,
  isForPresentation,
}: IProps) => {
  const { i18n } = useGlobalStore();
  return (
    <div className={style.deliveryDescriptionContainer}>
      <div className={style.deliveryDescription}>
        <span
          className={
            isForPresentation
              ? style.deliveryDescriptionTitleBlack
              : style.deliveryDescriptionTitle
          }
        >
          {i18n.t("delivery_description_handling_title")}
        </span>
        <label
          className={
            isForPresentation
              ? style.deliveryDescriptionTextBlack
              : style.deliveryDescriptionText
          }
        >
          {labelDescription.handling}
        </label>
      </div>
      <div className={style.deliveryDescription}>
        <span
          className={
            isForPresentation
              ? style.deliveryDescriptionTitleBlack
              : style.deliveryDescriptionTitle
          }
        >
          {i18n.t("delivery_description_packaging_title")}
        </span>
        <label
          className={
            isForPresentation
              ? style.deliveryDescriptionTextBlack
              : style.deliveryDescriptionText
          }
        >
          {labelDescription.packaging}
        </label>
      </div>
    </div>
  );
};

export default DeliveryDescription;
