import { IModel } from "shared/lib/models/model";
import { PersistenceKeys } from "../base";
import OrderRequest, { initialCreditCard } from "../../models/orderRequest";
import { IUpsertOrderResponse } from "../../endpoints/orders";
import Address from "../../models/address";

export function setModel(model?: IModel) {
  if (!model) {
    sessionStorage.removeItem(PersistenceKeys.OrderModel);
    return;
  }
  sessionStorage.setItem(PersistenceKeys.OrderModel, JSON.stringify(model));
}

export function getModel(): IModel | undefined {
  const storedModel = sessionStorage.getItem(PersistenceKeys.OrderModel);

  if (storedModel) {
    return JSON.parse(storedModel);
  }
  return undefined;
}

export function setName(name?: string) {
  if (!name) {
    sessionStorage.removeItem(PersistenceKeys.OrderName);
    return;
  }

  sessionStorage.setItem(PersistenceKeys.OrderName, name);
}

export function getName(): string | undefined {
  return sessionStorage.getItem(PersistenceKeys.OrderName) || undefined;
}

function mapAddress(address: Address): Address {
  return {
    address1: address.address1 || null,
    address2: address.address2 || null,
    city: address.city || null,
    provinceState: address.provinceState || null,
    country: address.country || null,
    zipCode: address.zipCode || null,
  };
}

export function setRequest(request?: OrderRequest) {
  if (!request) {
    sessionStorage.removeItem(PersistenceKeys.OrderRequest);
    return;
  }

  const _request: OrderRequest = {
    firstName: request.firstName || null,
    lastName: request.lastName || null,
    email: request.email || null,
    phoneNumber: request.phoneNumber || null,
    sameAsShippingAddress: request.sameAsShippingAddress,
    shippingAddress: mapAddress(request.shippingAddress),
    billingAddress: mapAddress(request.billingAddress),
    deliveryMethod: request.deliveryMethod,
    creditCard: initialCreditCard,
  };

  sessionStorage.setItem(
    PersistenceKeys.OrderRequest,
    JSON.stringify(_request),
  );
}

export function getRequest(): OrderRequest | undefined {
  const storedRequest = sessionStorage.getItem(PersistenceKeys.OrderRequest);

  if (storedRequest) {
    return JSON.parse(storedRequest);
  }
  return undefined;
}

export function setResponse(response?: IUpsertOrderResponse) {
  if (!response) {
    sessionStorage.removeItem(PersistenceKeys.OrderResponse);
    return;
  }

  sessionStorage.setItem(
    PersistenceKeys.OrderResponse,
    JSON.stringify(response),
  );
}

export function getResponse(): IUpsertOrderResponse | undefined {
  const storedResponse = sessionStorage.getItem(PersistenceKeys.OrderResponse);

  if (storedResponse) {
    return JSON.parse(storedResponse);
  }
  return undefined;
}
