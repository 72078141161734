import React from "react";

import C from "classnames";
import style from "./index.module.styl";

interface IRadioButtonProps {
  readonly name: string;
  readonly checked?: boolean;
  readonly tabIndex?: number;
  readonly onClick?: () => void;
  readonly id?: string;
  readonly className?: string;
  readonly disabled?: boolean;
}

export default function Radio({
  name,
  checked,
  tabIndex,
  onClick,
  disabled,
  id,
  className,
}: IRadioButtonProps) {
  return (
    <label className={`${style.label} ${className || ""}`}>
      <input
        type="radio"
        className={style.radio}
        name={name}
        tabIndex={tabIndex}
        disabled={disabled}
        onChange={() => (!!onClick ? onClick() : undefined)}
        checked={checked != null ? checked : undefined}
        id={id || undefined}
      />
      <div className={C(style.view, style.checked)}>
        <img
          src={require("../../../images/radio-button-on.svg")}
          alt="radio on"
        />
      </div>
      <div className={C(style.view, style.notChecked)}>
        <img
          src={require("../../../images/radio-button-off.svg")}
          alt="radio off"
        />
      </div>
    </label>
  );
}
