import React from "react";
import C from "classnames";

import style from "./index.module.styl";

interface ICheckboxProps {
  readonly checked: boolean;
  readonly onChange?: () => void;
  readonly tabIndex?: number;
  readonly className?: string;
  readonly id?: string;
  readonly isAddIcon?: boolean;
  readonly disabled?: boolean;
}

export default function Checkbox({
  checked,
  onChange,
  tabIndex,
  className,
  disabled,
  id,
  isAddIcon,
}: ICheckboxProps) {
  return (
    <label className={C(style.label, { [className || ""]: !!className })}>
      <input
        className={style.checkbox}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onClick={event => event.stopPropagation()}
        onChange={() => (!!onChange ? onChange() : undefined)}
        tabIndex={tabIndex || undefined}
        id={id}
      />
      <div
        className={C(style.view, style.checked, { [style.disabled]: disabled })}
      >
        <img src={require("../../../images/checkbox-on.svg")} alt="checkbox" />
      </div>
      <div
        className={C(style.view, style.notChecked, {
          [style.disabled]: disabled,
        })}
      >
        {isAddIcon ? (
          <img src={require("../../../images/icons/add.svg")} alt="add" />
        ) : (
          <img
            src={require("../../../images/checkbox-off.svg")}
            alt="checkbox off"
          />
        )}
      </div>
    </label>
  );
}
