import React, { useMemo, useEffect, useState } from "react";
import FormInput from "../../input";
import style from "../index.module.styl";
import { useGlobalStore } from "../../../../store";
import Address from "../../../../models/address";
import DropSelect from "../../dropSelect";
import Countries from "shared/lib/enums/countries";
import Provinces from "shared/lib/enums/provinces";
import States from "shared/lib/enums/states";
import { OrderAddresses } from "../../../../models/orderRequest";
import { countriesMap } from "shared/lib/data/countries";
import { provincesMap } from "shared/lib/data/provinces";
import { statesMap } from "shared/lib/data/states";
import Checkbox from "../../checkbox";
import { IFieldErrors } from "../contactInformationForm";
import { ErrorObject } from "ajv";

interface IProps {
  readonly address?: Partial<Address>;
  readonly shippingAddress?: Partial<Address>;
  readonly formKey: keyof OrderAddresses;
  readonly title: string;
  readonly errors: string[];
  readonly sameAsShippingAddress: boolean;
  readonly onChange: (
    key: keyof OrderAddresses,
    addressKey: keyof Address | undefined,
    value: any,
  ) => void;
}

type AddressDropdownItems = Pick<Address, "country" | "provinceState">;
type AddressDropdownItem = { label: string; value: string };

export default function AddressForm({
  address,
  formKey,
  onChange,
  shippingAddress,
  title,
  errors,
  sameAsShippingAddress,
}: IProps) {
  const { i18n } = useGlobalStore();
  const [provincesOrStates, setProvincesOrStates] = useState<
    AddressDropdownItem[]
  >([]);
  const [countrySelectorOpen, setCountrySelectorOpen] = useState(false);
  const [provinceSelectorOpen, setProvinceSelectorOpen] = useState(false);
  const [isBillingAddressForm, _] = useState(
    formKey === "billingAddress" && !!shippingAddress,
  );

  const [fieldErrors, setFieldErrors] = useState<IFieldErrors>({});

  const zipCodeErrorDescription = {
    keyword: "invalid",
    message: i18n.t("order_error_invalid_zip_code"),
  };

  const errorKeys: string[] = ["zipCode"];

  useEffect(() => {
    const tempErrors: IFieldErrors = {};
    errorKeys.forEach((key: string) => {
      if (
        errors &&
        errors.includes(key) &&
        address?.[key as keyof Address] != null
      ) {
        tempErrors[key] = {
          dataPath: key,
          keyword: "invalid",
        } as ErrorObject;
      } else {
        tempErrors[key] = {} as ErrorObject;
      }
    });
    setFieldErrors(tempErrors);
  }, [errors]);

  const countriesItems = useMemo<AddressDropdownItem[]>(() => {
    return Object.values(Countries).map(country => {
      return {
        value: country,
        label: i18n.translatable(countriesMap[country]),
      };
    });
  }, []);

  const provincesItems = useMemo<AddressDropdownItem[]>(() => {
    return Object.values(Provinces).map(province => {
      return {
        value: province,
        label: i18n.translatable(provincesMap[province]),
      };
    });
  }, []);

  const statesItems = useMemo<AddressDropdownItem[]>(() => {
    return Object.values(States).map(state => {
      return {
        value: state,
        label: i18n.translatable(statesMap[state]),
      };
    });
  }, []);

  useEffect(() => {
    if (!address || !address.country) {
      setProvincesOrStates([]);
      return;
    }
    setProvincesOrStates(
      address.country === Countries.Canada ? provincesItems : statesItems,
    );
  }, [countrySelectorOpen]);

  const handleDropdownSelection = (
    key: keyof AddressDropdownItems,
    value: string,
  ) => {
    onChange(formKey, key, value);
    setCountrySelectorOpen(false);
    setProvinceSelectorOpen(false);
  };

  const dropSelectItemsRender = ({
    items,
    name,
  }: {
    items: { label: string; value: string }[];
    name: keyof AddressDropdownItems;
  }) => {
    return (
      <>
        {!!items && (
          <div className={style.selectorItemsWrapper}>
            {items.map(item => (
              <button
                key={item.value}
                onClick={() => handleDropdownSelection(name, item.value)}
              >
                <p>{item.label}</p>
              </button>
            ))}
          </div>
        )}
      </>
    );
  };

  const countryTitle = () => {
    return !!address?.country
      ? i18n.translatable(countriesMap[address.country])
      : i18n.t("country_placeholder");
  };

  const provinceTitle = () => {
    if (!address || !address.country) {
      return i18n.t("province_state_placeholder");
    }
    if (!!address.provinceState) {
      return address.country === Countries.Canada
        ? i18n.translatable(provincesMap[address.provinceState as Provinces])
        : i18n.translatable(statesMap[address.provinceState as States]);
    }

    return address.country === Countries.Canada
      ? i18n.t("province_placeholder")
      : i18n.t("state_placeholder");
  };

  const shippingAddressCountryTitle = () => {
    return !!shippingAddress && shippingAddress.country
      ? i18n.translatable(countriesMap[shippingAddress.country])
      : i18n.t("country_placeholder");
  };

  const shippingAddressProvinceTitle = () => {
    if (!shippingAddress || !shippingAddress.provinceState) {
      return "";
    }
    return shippingAddress.country === Countries.Canada
      ? i18n.translatable(
          provincesMap[shippingAddress.provinceState as Provinces],
        )
      : i18n.translatable(statesMap[shippingAddress.provinceState as States]);
  };

  const formWrapperStyle = () => {
    if (sameAsShippingAddress) {
      return { maxHeight: 0, overflow: "hidden" };
    } else {
      return { maxHeight: 1000, overflow: "visible" };
    }
  };

  const sameAddressTextStyle = () => {
    if (sameAsShippingAddress) {
      return { maxHeight: 1000, overflow: "visible" };
    } else {
      return { maxHeight: 0, overflow: "hidden" };
    }
  };
  return (
    <>
      <div className={style.wrapper}>
        <h3 className={style.title}>{title}</h3>
        {isBillingAddressForm && (
          <div className={style.sameAddressWrapper}>
            <div className={style.sameAddressCheckboxWrapper}>
              <Checkbox
                checked={sameAsShippingAddress}
                onChange={() => {
                  onChange(
                    "sameAsShippingAddress",
                    undefined,
                    !sameAsShippingAddress,
                  );
                }}
              />
              <div>
                <span>{i18n.t("billing_address_same")}</span>
                <div
                  className={style.sameAddressText}
                  style={sameAddressTextStyle()}
                >
                  {shippingAddress!.address1 +
                    " " +
                    (shippingAddress?.address2 || "")}
                  <br />
                  {`${
                    shippingAddress!.city
                  }, ${shippingAddressProvinceTitle()}, ${shippingAddressCountryTitle()}`}
                  <br />
                  {`${shippingAddress!.zipCode}`}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={style.formWrapper} style={formWrapperStyle()}>
          <FormInput
            className={style.input}
            inputProps={{
              onChange: event =>
                onChange(formKey, "address1", event.target.value),
              placeholder: i18n.t("address_1_placeholder"),
              value: address?.address1 || undefined,
            }}
          ></FormInput>
          <FormInput
            className={style.input}
            inputProps={{
              onChange: event =>
                onChange(formKey, "address2", event.target.value),
              placeholder: i18n.t("address_2_placeholder"),
              value: address?.address2 || undefined,
            }}
          ></FormInput>
          <FormInput
            className={style.input}
            inputProps={{
              onChange: event => onChange(formKey, "city", event.target.value),
              placeholder: i18n.t("city_placeholder"),
              value: address?.city || undefined,
            }}
          ></FormInput>
          <div className={style.selectorsWrapper}>
            <div className={style.selectorWrapper}>
              <DropSelect
                title={countryTitle()}
                expandFunction={dropSelectItemsRender}
                expandProps={{ items: countriesItems, name: "country" }}
                openFromOutside={countrySelectorOpen}
                openStateListener={setCountrySelectorOpen}
              />
            </div>
            {!!address?.country && (
              <div className={style.selectorWrapper}>
                <DropSelect
                  title={provinceTitle()}
                  expandFunction={dropSelectItemsRender}
                  expandProps={{
                    items: provincesOrStates,
                    name: "provinceState",
                  }}
                  openFromOutside={provinceSelectorOpen}
                  openStateListener={setProvinceSelectorOpen}
                />
              </div>
            )}
          </div>
          <FormInput
            className={style.input}
            dataPath={"zipCode"}
            errors={[fieldErrors?.["zipCode"]]}
            errorDescriptions={[zipCodeErrorDescription]}
            inputProps={{
              value: address?.zipCode || undefined,
              onChange: event =>
                onChange(formKey, "zipCode", event.target.value.toUpperCase()),
              placeholder: i18n.t("zip_placeholder"),
            }}
          ></FormInput>
        </div>
      </div>
    </>
  );
}
