import React, { useState, useEffect } from "react";
import FormInput from "../../input";
import { ContactInformation } from "../../../../models/orderRequest";
import style from "../index.module.styl";
import { useGlobalStore } from "../../../../store";
import { ErrorObject } from "ajv";

interface IProps {
  readonly contactInformation: Partial<ContactInformation>;
  readonly onChange: (key: keyof ContactInformation, value: string) => void;
  readonly errors: string[];
}
export interface IFieldErrors {
  [key: string]: ErrorObject;
}

export default function ContactInformationForm({
  contactInformation,
  onChange,
  errors,
}: IProps) {
  const { i18n } = useGlobalStore();
  const [fieldErrors, setFieldErrors] = useState<IFieldErrors>({});

  const emailErrorDescription = {
    keyword: "invalid",
    message: i18n.t("order_error_invalid_email"),
  };
  const phoneErrorDescription = {
    keyword: "invalid",
    message: i18n.t("order_error_invalid_phone_number"),
  };

  const errorKeys: string[] = ["email", "phoneNumber"];

  useEffect(() => {
    const tempErrors: IFieldErrors = {};
    errorKeys.forEach((key: string) => {
      if (
        errors.includes(key) &&
        contactInformation[key as keyof ContactInformation] != null
      ) {
        tempErrors[key] = {
          dataPath: key,
          keyword: "invalid",
        } as ErrorObject;
      } else {
        tempErrors[key] = {} as ErrorObject;
      }
    });
    setFieldErrors(tempErrors);
  }, [errors]);

  return (
    <>
      <div className={style.wrapper}>
        <h3 className={style.title}>{i18n.t("contact_infos_title")}</h3>
        <FormInput
          className={style.input}
          inputProps={{
            onChange: event => onChange("firstName", event.target.value),
            placeholder: i18n.t("contact_infos_firstname_placeholder"),
            value: contactInformation.firstName || undefined,
          }}
        ></FormInput>
        <FormInput
          className={style.input}
          inputProps={{
            onChange: event => onChange("lastName", event.target.value),
            placeholder: i18n.t("contact_infos_lastname_placeholder"),
            value: contactInformation.lastName || undefined,
          }}
        ></FormInput>
        <FormInput
          className={style.input}
          dataPath={"email"}
          errors={[fieldErrors?.["email"]]}
          errorDescriptions={[emailErrorDescription]}
          inputProps={{
            onChange: event => onChange("email", event.target.value),
            placeholder: i18n.t("contact_infos_email_placeholder"),
            value: contactInformation.email || undefined,
          }}
        ></FormInput>
        <FormInput
          className={style.input}
          dataPath={"phoneNumber"}
          errors={[fieldErrors?.["phoneNumber"]]}
          errorDescriptions={[phoneErrorDescription]}
          inputMask="(999) 999-9999"
          inputProps={{
            onChange: event => onChange("phoneNumber", event.target.value),
            placeholder: i18n.t("contact_infos_phonenumber_placeholder"),
            value: contactInformation.phoneNumber || undefined,
          }}
        ></FormInput>
      </div>
    </>
  );
}
