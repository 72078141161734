import React, { useRef, useEffect, useState } from "react";
import Radio from "../forms/radio";
import style from "./index.module.styl";
import { useGlobalStore } from "../../store";
import DeliveryDescription, {
  IDeliveryDescription,
} from "../deliveryDescription";
import C from "classnames";

interface IProps {
  name: string;
  label: string;
  labelDescription: IDeliveryDescription;
  checked?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  price?: number;
  isIncluded?: boolean;
  isForPresentation?: boolean;
}

const DeliveryRadio = ({
  name,
  label,
  labelDescription,
  checked,
  onClick,
  isDisabled,
  price,
  isIncluded,
  isForPresentation,
}: IProps) => {
  const { i18n } = useGlobalStore();
  const [totalHeight, setTotalHeight] = useState<number | undefined>(undefined);
  const [heightLoaded, setHeightLoaded] = useState<boolean>(false);
  const deliveryDescription = useRef(null);
  const delivery = useRef(null);

  const updateComponentHeight = () => {
    const descriptionHeight = deliveryDescription.current?.scrollHeight;
    const deliveryHeight = delivery.current?.scrollHeight;
    if (!deliveryHeight) setTotalHeight(undefined);
    else {
      setTimeout(function() {
        setHeightLoaded(true);
      }, 400);
      setTotalHeight(
        checked ? descriptionHeight + deliveryHeight : deliveryHeight,
      );
    }
  };

  useEffect(updateComponentHeight, [
    delivery.current,
    deliveryDescription.current,
    checked,
  ]);

  useEffect(() => {
    window.addEventListener("resize", updateComponentHeight);
    return () => window.removeEventListener("resize", updateComponentHeight);
  });

  return (
    <div
      style={{
        maxHeight: !!totalHeight ? totalHeight : "unset",
        transition: heightLoaded ? "all 400ms ease" : "none",
      }}
    >
      <label
        ref={delivery}
        className={C(
          isForPresentation
            ? style.deliveryRadioInputNoCursor
            : isDisabled
            ? style.deliveryRadioInputDisabled
            : style.deliveryRadioInput,
        )}
      >
        {isForPresentation ? (
          <li tabIndex={-1} className={style.deliveryRadioControlList} />
        ) : (
          <Radio
            name={name}
            tabIndex={-1}
            checked={checked}
            onClick={() => onClick()}
            className={style.deliveryRadioControl}
            disabled={isDisabled}
          />
        )}
        <p
          className={
            isForPresentation
              ? style.deliveryRadioTextBlack
              : style.deliveryRadioText
          }
        >
          {label}
        </p>
        <div className={style.deliveryPriceLabel}>
          {!!price && !isIncluded && (
            <p
              className={
                isForPresentation
                  ? style.deliveryRadioPriceBlack
                  : style.deliveryRadioPrice
              }
            >
              $ {price}
            </p>
          )}
          {isIncluded && (
            <p
              className={
                isForPresentation
                  ? style.deliveryRadioIncludedPriceBlack
                  : style.deliveryRadioIncludedPrice
              }
            >
              {i18n.t("configuration_price_included")}
            </p>
          )}
        </div>
      </label>
      <div
        className={
          isDisabled
            ? style.deliveryRadioTextsDisabled
            : style.deliveryRadioTexts
        }
        ref={deliveryDescription}
      >
        <div className={style.deliveryRadioFormWrapper}>
          {checked && (
            <DeliveryDescription
              isForPresentation={isForPresentation}
              labelDescription={labelDescription}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryRadio;
