import React, { useEffect, useRef } from "react";
import FormInput from "../../input";
import style from "../index.module.styl";
import creditCardStyle from "./index.module.styl";
import { useGlobalStore } from "../../../../store";
import CreditCard from "../../../../models/creditCard";
import C from "classnames";
import { toRGB, GREYISH_BROWN, rem } from "../../../../utils/style";

const STRIPE_INPUT_STYLE = {
  base: {
    color: toRGB(GREYISH_BROWN),
    lineHeight: rem(24),
    fontFamily: "'Lato', sans-serif",
    fontWeight: 400,
    fontSize: rem(18),

    "::placeholder": {
      color: toRGB(GREYISH_BROWN),
    },
  },
};

interface IProps {
  readonly onChange: (key: keyof CreditCard, value: any) => void;
  readonly stripe: stripe.Stripe;
}
export default function CreditCardForm({ onChange, stripe }: IProps) {
  const { i18n } = useGlobalStore();
  const creditCardRef = useRef<HTMLDivElement>(null);
  const cvvRef = useRef<HTMLDivElement>(null);
  const expiryRef = useRef<HTMLDivElement>(null);
  const didLoad = useRef(false);

  useEffect(() => {
    if (
      !creditCardRef.current ||
      !expiryRef.current ||
      !cvvRef.current ||
      !stripe ||
      !onChange ||
      didLoad.current
    ) {
      return;
    }

    didLoad.current = true;

    const elements = stripe.elements();

    const cardNumberElement = elements.create("cardNumber", {
      style: STRIPE_INPUT_STYLE,
      placeholder: i18n.t("order_credit_card_number"),
    });
    cardNumberElement.mount(creditCardRef.current);

    const cardExpiryElement = elements.create("cardExpiry", {
      style: STRIPE_INPUT_STYLE,
      placeholder: i18n.t("order_credit_card_expiry"),
    });
    cardExpiryElement.mount(expiryRef.current);

    const cardCvvElement = elements.create("cardCvc", {
      style: STRIPE_INPUT_STYLE,
      placeholder: i18n.t("order_credit_card_cvv"),
    });
    cardCvvElement.mount(cvvRef.current);

    cardNumberElement?.on("change", () =>
      onChange("cardNumber", cardNumberElement),
    );
    cardExpiryElement?.on("change", () => onChange("exp", cardExpiryElement));
    cardCvvElement?.on("change", () => onChange("cvv", cardCvvElement));
  }, [
    stripe,
    creditCardRef.current,
    expiryRef.current,
    cvvRef.current,
    onChange,
  ]);

  return (
    <>
      <div className={style.wrapper}>
        <h3 className={style.title}>{i18n.t("order_credit_card_title")}</h3>
        <FormInput
          className={style.input}
          inputProps={{
            onChange: event => onChange("fullName", event.target.value),
            placeholder: i18n.t("order_credit_card_name"),
          }}
        ></FormInput>
        <div
          className={C(style.input, "input", creditCardStyle.stripeInput)}
          style={{ width: "100%" }}
          ref={creditCardRef}
        ></div>

        <div className={style.formSideBySide}>
          <div className={style.formSideBySideLeft}>
            <div
              className={C(style.input, "input", creditCardStyle.stripeInput)}
              style={{ width: "100%" }}
              ref={expiryRef}
            ></div>
          </div>
          <div className={style.formSideBySideRight}>
            <div
              className={C(style.input, "input", creditCardStyle.stripeInput)}
              style={{ width: "100%" }}
              ref={cvvRef}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
