import React from "react";
import style from "./index.module.styl";
import { DeliveryMethod } from "shared/lib/models/deliveryMethod";
import DeliveryRadio from "../deliveryRadio";
import { useGlobalStore } from "../../store";
import { getDeliveryMethodPrice } from "shared/lib/utils/deliveryMethod";

interface IDeliveryMethodSelectorProps {
  onChooseDelivery: (method: DeliveryMethod) => void;
  chosenDeliveryMethod: DeliveryMethod;
  isDisabled: boolean;
}

export default function DeliveryMethodSelector({
  onChooseDelivery,
  chosenDeliveryMethod,
  isDisabled,
}: IDeliveryMethodSelectorProps) {
  const { i18n } = useGlobalStore();
  return (
    <div className={style.deliveryContainer}>
      <DeliveryRadio
        isDisabled={isDisabled}
        name="deliveryMethod"
        label={i18n.t("delivery_pick_up_label")}
        labelDescription={{
          handling: i18n.t("delivery_pick_up_handling"),
          packaging: i18n.t("delivery_pick_up_packaging"),
        }}
        checked={chosenDeliveryMethod === DeliveryMethod.PickUp}
        onClick={() => onChooseDelivery(DeliveryMethod.PickUp)}
        isIncluded={true}
      ></DeliveryRadio>
      <DeliveryRadio
        isDisabled={isDisabled}
        name="deliveryMethod"
        label={i18n.t("delivery_outside_home_label")}
        labelDescription={{
          handling: i18n.t("delivery_outside_home_handling"),
          packaging: i18n.t("delivery_outside_home_packaging"),
        }}
        checked={chosenDeliveryMethod === DeliveryMethod.OutsideHome}
        onClick={() => onChooseDelivery(DeliveryMethod.OutsideHome)}
        price={getDeliveryMethodPrice(DeliveryMethod.OutsideHome)}
      ></DeliveryRadio>
      <DeliveryRadio
        isDisabled={isDisabled}
        name="deliveryMethod"
        label={i18n.t("delivery_inside_home_label")}
        labelDescription={{
          handling: i18n.t("delivery_inside_home_handling"),
          packaging: i18n.t("delivery_inside_home_packaging"),
        }}
        checked={chosenDeliveryMethod === DeliveryMethod.InsideHome}
        onClick={() => onChooseDelivery(DeliveryMethod.InsideHome)}
        price={getDeliveryMethodPrice(DeliveryMethod.InsideHome)}
      ></DeliveryRadio>
      <div
        className={
          isDisabled ? style.deliveryWarningDisabled : style.deliveryWarning
        }
      >
        {chosenDeliveryMethod === DeliveryMethod.PickUp ? (
          <p>{i18n.t("delivery_warning_pick_up")}</p>
        ) : (
          <p>{i18n.t("delivery_warning_home")}</p>
        )}
      </div>
    </div>
  );
}
