import Address from "./address";
import CreditCard from "./creditCard";
import { DeliveryMethod } from "shared/lib/models/deliveryMethod";
import Countries from "shared/lib/enums/countries";

export type ContactInformation = Pick<
  OrderRequest,
  "firstName" | "lastName" | "email" | "phoneNumber"
>;

export type OrderAddresses = Pick<
  OrderRequest,
  "shippingAddress" | "billingAddress" | "sameAsShippingAddress"
>;

export default interface OrderRequest {
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly email: string | null;
  readonly phoneNumber: string | null;
  readonly sameAsShippingAddress: boolean;
  readonly shippingAddress: Address;
  readonly billingAddress: Address;
  readonly creditCard: CreditCard;
  readonly deliveryMethod: DeliveryMethod;
}

const initialAddress: Address = {
  address1: null,
  address2: null,
  city: null,
  country: null,
  provinceState: null,
  zipCode: null,
};

export const initialCreditCard: CreditCard = {
  cardNumber: null,
  cvv: null,
  exp: null,
  fullName: null,
};

export const initialOrderRequest: OrderRequest = {
  firstName: null,
  lastName: null,
  email: null,
  phoneNumber: null,
  sameAsShippingAddress: true,
  shippingAddress: initialAddress,
  billingAddress: initialAddress,
  creditCard: initialCreditCard,
  deliveryMethod: DeliveryMethod.PickUp,
};

export function validateOrderRequest(
  request: OrderRequest,
  ignoredFields: Set<keyof OrderRequest>,
): string[] {
  let errors: string[] = [];

  Object.entries(request).forEach(([key, value]) => {
    if (
      !ignoredFields.has(key as keyof OrderRequest) &&
      key !== "sameAsShippingAddress"
    ) {
      switch (key as keyof OrderRequest) {
        case "billingAddress":
          if (!request.sameAsShippingAddress && value) {
            errors.push(...validateAddress(value as Address));
          }
          break;
        case "shippingAddress":
          if (value) {
            errors.push(...validateAddress(value as Address));
          }
          break;
        case "creditCard":
          if (value) {
            errors.push(...validateCreditCard(value as CreditCard));
          }
          break;
        case "email":
          if (!validateEmail(value as string)) {
            errors.push(key);
          }
          break;
        case "phoneNumber":
          if (!validatePhoneNumber(value as string)) {
            errors.push(key);
          }
          break;
        default:
          if (!value) {
            errors.push(key);
          }
          break;
      }
    }
  });

  return errors;
}

function validateCreditCard(creditCard: CreditCard): string[] {
  let errors: string[] = [];

  Object.entries(creditCard).forEach(([key, value]) => {
    if (!value || (typeof value == "object" && !value._complete)) {
      errors.push(key);
    }
  });

  return errors;
}

function validateAddress(address: Address): string[] {
  let errors: string[] = [];

  Object.entries(address).forEach(([key, value]) => {
    if (
      key === "zipCode" &&
      address.country &&
      !validateZipCode(value, address.country)
    ) {
      errors.push(key);
    } else if (!value && key !== "address2") {
      errors.push(key);
    }
  });

  return errors;
}

function validateZipCode(zipCode: string, country: Countries): boolean {
  let re = /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/;
  country === Countries.Canada
    ? (re = /^([A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/)
    : country === Countries.US
    ? (re = /^(\d{5}(-\d{4})?)$/)
    : re;
  return re.test(zipCode);
}

function validatePhoneNumber(phoneNumber: string): boolean {
  var re = /^[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
  return re.test(phoneNumber);
}

function validateEmail(email: string): boolean {
  // Regex grabbed from stackoverflow
  // Tested on regexr and appeared to be working
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validate(obj: any): string[] {
  let errors: string[] = [];

  Object.entries(obj).forEach(([key, value]) => {
    if (!value) {
      errors.push(key);
    }
  });

  return errors;
}

const ISO_COUNTRY_MAP: Record<Countries, string> = {
  [Countries.Canada]: "CA",
  [Countries.US]: "US",
};

export function isoCountry(country: Countries) {
  return ISO_COUNTRY_MAP[country];
}
