import React from "react";
import style from "./index.module.styl";
import C from "classnames";

interface IProgressMobileProps {
  readonly items: string[];
  readonly index: number;
}

export default function ProgressMobile({ items, index }: IProgressMobileProps) {
  const title = items[index];
  return (
    <div className={style.progress}>
      <div className={style.progressTitle}>{title}</div>
      <div className={style.progressDots}>
        {items.map((_, i) => (
          <div
            key={i}
            className={C(style.progressDot, {
              [style.progressDotActive]: i <= index,
            })}
          ></div>
        ))}
      </div>
    </div>
  );
}
