import React, { useState, useEffect, useRef, useCallback } from "react";
import GeneralLayout from "../../layouts/general";
import page from "..";
import ContactInformationForm from "../../forms/forms/contactInformationForm";
import OrderRequest, {
  ContactInformation,
  OrderAddresses,
  initialOrderRequest,
  validateOrderRequest,
  isoCountry,
} from "../../../models/orderRequest";
import AddressForm from "../../forms/forms/addressForm";
import CenterColumn from "../../centerColumn";
import Header from "../../header";
import ProgressMobile from "../../progress/mobile";
import Address from "../../../models/address";
import style from "./index.module.styl";
import { ORDER_STEPS } from "../../../common/payment";
import { useGlobalStore } from "../../../store";
import C from "classnames";
import { IModel } from "shared/lib/models/model";
import { navigate } from "gatsby";
import { ROUTES } from "../../../common/routes.js";
import SEO from "../../seo";
import OrderSummary from "./summary";
import { useEffectAsync } from "../../../utils/effects";
import {
  createOrder,
  finalizeOrder,
  IUpsertOrderResponse,
  payOrder,
} from "../../../endpoints/orders";
import { IConfiguration } from "shared/lib/models/configuration";
import CreditCardForm from "../../forms/forms/creditCardForm";
import CreditCard from "../../../models/creditCard";

import Confirmation from "./views/confirmation";
import { ILocale } from "shared/lib/locales/base";
import Spinner from "../../spinner";
import { rem, toRGB, COD_GREY } from "../../../utils/style";
import {
  ICompletedOrderRequest,
  ICompletedOrderRequestAddress,
} from "shared/lib/models/order";
import {
  setModel as setOrderModel,
  getModel as getOrderModel,
  setName as setOrderName,
  getName as getOrderName,
  setRequest,
  getRequest,
  getResponse,
  setResponse,
} from "../../../persistance/order";
import Countries from "shared/lib/enums/countries";
import Provinces from "shared/lib/enums/provinces";
import States from "shared/lib/enums/states";
import { DeliveryMethod } from "shared/lib/models/deliveryMethod";
import { getDeliveryMethodPrice } from "shared/lib/utils/deliveryMethod";
import DeliveryMethodSelector from "../../deliveryMethodSelector";
import SecurityNotice from "./views/securityNotice";
import {
  sendInitiateCheckoutEvent,
  sendAddPaymentInfoEvent,
} from "../../../common/analytics";

interface IProps {
  readonly location: {
    state: {
      model: IModel;
      collectionName: string;
      configuration: IConfiguration;
    };
  };
}

function Order({ location }: IProps) {
  const { i18n, makeLink } = useGlobalStore();

  const [orderRequest, setOrderRequest] = useState(initialOrderRequest);
  const [ignoredFields, setIgnoredFields] = useState(
    new Set<keyof OrderRequest>(["billingAddress", "creditCard"]),
  );

  const [requestIsValid, setRequestIsValid] = useState(false);
  const [shippingAddressIsValid, setShippingAddressIsValid] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [formattedError, setFormattedError] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [pageTitle, setPageTitle] = useState(i18n.t(ORDER_STEPS[0].i18nKey));
  const [order, setOrder] = useState<IUpsertOrderResponse | undefined>(
    undefined,
  );
  const [orderCreatedAt, setOrderCreatedAt] = useState<Date | undefined>();

  const [model, setModel] = useState(location?.state?.model);
  const [collectionName, setCollectionName] = useState(
    location?.state?.collectionName || "",
  );

  const [stripe, setStripe] = useState<stripe.Stripe | null>(null);
  const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);
  const didLoad = useRef(false);
  const [isRunningRequest, setIsRunningRequest] = useState(false);
  const [errorKey, setErrorKey] = useState<keyof ILocale | undefined>(
    undefined,
  );
  const isInit = useRef(false);
  const requiresOrder = useRef(false);
  const didFetchOrder = useRef(false);

  useEffect(() => {
    if (model && collectionName && orderRequest) {
      isInit.current = true;
      requiresOrder.current = true;
      setOrderModel(model);
      setOrderName(collectionName);
      setRequest(orderRequest);
    } else {
      const _model = getOrderModel();
      const _collectionName = getOrderName();
      let _orderRequest = getRequest();
      const _response = getResponse();

      if (!(_model && _collectionName && _orderRequest && _response)) {
        window.location.pathname = "/";
        return;
      }

      isInit.current = true;
      setModel(_model);
      setCollectionName(_collectionName);
      setOrderRequest(_orderRequest);
      setOrder(_response);
    }
  }, []);

  useEffect(() => {
    if (isInit.current) {
      setRequest(orderRequest);
    }
  }, [orderRequest]);

  useEffect(() => {
    if (isInit.current) {
      setResponse(order);
    }
  }, [order]);

  useEffect(() => {
    let _ignoredFields = new Set<keyof OrderRequest>([]);

    if (currentStep === 0) {
      _ignoredFields = new Set([
        ...ignoredFields,
        "billingAddress",
        "creditCard",
      ]);
    } else if (!!orderRequest.sameAsShippingAddress) {
      _ignoredFields.add("billingAddress");
    }

    setIgnoredFields(_ignoredFields);
  }, [orderRequest, currentStep]);

  // Validates the current form state and sets error states accordingly
  useEffect(() => {
    const errors = validateOrderRequest(orderRequest, ignoredFields);

    const isValid = errors.length === 0;

    setErrors(errors);
    setRequestIsValid(isValid);
    if (isValid) {
      setFormattedError("");
      setShippingAddressIsValid(true);
      return;
    }
    let errorResult = i18n.t("order_error_prefix");
    const lastIndex = errors.length - 1;
    const suffix = i18n.t("order_error_suffix");
    errors.forEach((error, index) => {
      const errorText = i18n.t(`order_error_${error}` as keyof ILocale);
      const prefix =
        index === 0
          ? " "
          : index === lastIndex
          ? ` ${i18n.t("order_error_and")} `
          : ", ";
      errorResult += prefix + errorText;
    });
    errorResult += ` ${suffix}`;
    setFormattedError(errorResult);
    setShippingAddressIsValid(
      !errors.some(key =>
        ["address1", "city", "country", "provinceState", "zipCode"].includes(
          key,
        ),
      ),
    );
  }, [orderRequest, currentStep, ignoredFields]);

  const [completedOrderRequest, setCompletedOrderRequest] = useState<
    ICompletedOrderRequest | undefined
  >(undefined);

  const setSelectedDeliveryMethod = (method: DeliveryMethod) => {
    setOrderRequest({
      ...orderRequest,
      deliveryMethod: method,
    });
  };

  const computeDeliveryMethodPrice = (method: DeliveryMethod) => {
    return getDeliveryMethodPrice(orderRequest.deliveryMethod!) * 100;
  };

  const handleContactInformationChange = (
    key: keyof ContactInformation,
    value: string,
  ) => {
    setOrderRequest({
      ...orderRequest,
      [key]: value,
    });
  };

  const handleAddressChange = (
    key: keyof OrderAddresses,
    addressKey: keyof Address | undefined,
    value: any,
  ) => {
    if (addressKey) {
      const addressObject = orderRequest[key] as Address;

      setOrderRequest({
        ...orderRequest,
        [key]: {
          ...addressObject,
          provinceState:
            addressKey === "country" ? null : addressObject.provinceState,
          [addressKey]: value,
        },
      });
    } else {
      setOrderRequest({
        ...orderRequest,
        [key]: value,
      });
    }
  };

  const handleCreditCardChange = (key: keyof CreditCard, value: any) => {
    setOrderRequest(orderRequest => {
      return {
        ...orderRequest,
        creditCard: {
          ...orderRequest.creditCard,
          [key]: value,
        },
      };
    });
  };

  const handleFinalizeOrder = async () => {
    if (!order) {
      return;
    }

    setIsRunningRequest(true);
    try {
      const _order = await finalizeOrder({
        token: order.token,
        province_state: orderRequest.shippingAddress!.provinceState!,
        country: orderRequest.shippingAddress!.country!,
        shipping: computeDeliveryMethodPrice(orderRequest.deliveryMethod!),
      });
      setOrder(_order);

      setIsRunningRequest(false);
    } catch (e) {
      // Do nothing;
      console.error(e);
      setIsRunningRequest(false);

      setErrorKey("order_network_error");

      return false;
    }

    return true;
  };

  const handlePayment = async () => {
    setIsRunningRequest(true);
    const {
      sameAsShippingAddress,
      shippingAddress,
      billingAddress,
      creditCard,
      firstName,
      lastName,
      email,
      phoneNumber,
      deliveryMethod,
    } = orderRequest;

    if (
      !stripe ||
      !order ||
      !orderRequest ||
      !shippingAddress ||
      !billingAddress ||
      !creditCard ||
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      !deliveryMethod ||
      sameAsShippingAddress === undefined
    ) {
      return;
    }

    const address = sameAsShippingAddress ? shippingAddress : billingAddress;

    if (!address.country) {
      return;
    }

    try {
      const paymentMethod = (
        await stripe.createPaymentMethod({
          type: "card",
          card: creditCard.cardNumber || undefined,
          billing_details: {
            name: creditCard.fullName,
            address: {
              city: address.city || undefined,
              country: isoCountry(address.country),
              line1: address.address1 || undefined,
              line2: address.address2 || undefined,
              postal_code: address.zipCode || undefined,
              state: address.provinceState || undefined,
            },
          },
        })
      ).paymentMethod;

      if (!paymentMethod) {
        throw "Payment method not available";
      }

      function getAddress(address: Address): ICompletedOrderRequestAddress {
        return {
          address1: address.address1 as string,
          address2: address.address2 || "",
          city: address.city as string,
          country: address.country as Countries,
          provinceState: address.provinceState as Provinces | States,
          zipCode: address.zipCode as string,
        };
      }

      const completedOrderRequest: ICompletedOrderRequest = {
        firstName,
        lastName,
        email,
        phoneNumber,
        shippingAddress: getAddress(shippingAddress as Address),

        billingAddress: sameAsShippingAddress
          ? getAddress(shippingAddress as Address)
          : getAddress(billingAddress as Address),
        creditCard: {
          fullName: creditCard.fullName!,
          last4: paymentMethod.card?.last4 || "",
        },
        deliveryMethod: orderRequest.deliveryMethod,
      };

      await payOrder({
        token: order.token,
        payment_method: paymentMethod.id,
        request: completedOrderRequest,
      });

      setCompletedOrderRequest(completedOrderRequest);
      setOrderCreatedAt(new Date());

      setIsRunningRequest(false);
      return true;
    } catch (e) {
      // Do nothing
      setIsRunningRequest(false);
      setErrorKey("order_creditcard_error");
      return false;
    }
  };

  const onNextPressed = async () => {
    setErrorKey(undefined);
    if (isRunningRequest) {
      return;
    }

    if (!requestIsValid) {
      setDisplayError(true);
      setTimeout(() => {
        setDisplayError(false);
      }, 3000);
      return;
    }

    if (currentStep === 0) {
      if (!(await handleFinalizeOrder())) {
        return;
      }
    }

    if (currentStep === 1) {
      if (!(await handlePayment())) {
        return;
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setResponse(undefined);
      setOrderModel(undefined);
      setOrderName(undefined);
      setRequest(undefined);
    }

    if (currentStep !== 2) {
      setCurrentStep(currentStep + 1);
      scrollTo(0, 0);
    }

    switch (currentStep) {
      case 0:
        sendInitiateCheckoutEvent(window, orderRequest);
        break;
      case 1:
        sendAddPaymentInfoEvent(window, orderRequest);
        break;
    }
  };

  const onPreviousPressed = () => {
    setErrorKey(undefined);
    if (isRunningRequest) {
      return;
    }

    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1);
    } else {
      if (!!model && !!collectionName) {
        navigate(makeLink(ROUTES.model(collectionName, model.name)), {
          state: {
            model,
          },
        });
      }
    }
  };

  const stepStyle = (step: number): React.CSSProperties => {
    const translate = step - currentStep;
    return {
      transform: translate ? `translate(${translate}00%)` : undefined,
      maxWidth: step === currentStep ? "100%" : 0,
      maxHeight: step === currentStep ? "100%" : 0,
    };
  };

  useEffect(() => {
    setPageTitle(i18n.t(ORDER_STEPS[currentStep].i18nKey));
  }, [currentStep]);

  useEffectAsync(
    async isMounted => {
      if (
        !(!didFetchOrder.current && requiresOrder.current && isInit.current)
      ) {
        return;
      }

      if (!model || !model.configuration) {
        return;
      }

      didFetchOrder.current = true;
      const order = await createOrder({ model });

      if (!isMounted()) {
        return;
      }

      setOrder(order);
    },
    [model],
  );

  useEffectAsync(
    isMounted => {
      if (!divRef) {
        return;
      }

      if (didLoad.current) {
        return;
      }

      didLoad.current = true;

      const script = document.createElement("script");

      script.src = "https://js.stripe.com/v3/";
      script.async = true;
      script.onload = () => {
        if (!isMounted()) {
          return;
        }

        setStripe(window.Stripe(process.env.GATSBY_STRIPE_PUBLIC_KEY || ""));
      };

      divRef.appendChild(script);
    },
    [divRef],
  );

  const handleDivRef = useCallback((ref: HTMLDivElement) => {
    setDivRef(ref);
  }, []);

  const buttonsRender = () => {
    return (
      <span className={style.buttonsContainer}>
        <button
          onClick={onPreviousPressed}
          className={C(
            "btn",
            "btn--animate-scale",
            "btn--bold",
            "btn--greyish-brown",
          )}
        >
          {i18n.t("previous_step")}
        </button>
        <button
          style={{ position: "relative" }}
          onClick={onNextPressed}
          onMouseEnter={() => setDisplayError(true)}
          onMouseLeave={() => setDisplayError(false)}
          className={C(
            "btn",
            "btn--animate-scale",
            "btn--blush",
            style.nextButton,
            {
              disabled: !requestIsValid,
            },
          )}
        >
          {i18n.t(currentStep === 0 ? "next_step" : "pay_now")}
          <span
            className={style.errorsOverlay}
            style={{ opacity: displayError && !requestIsValid ? 1 : 0 }}
          >
            <div className={style.errorArrow}></div>
            <span className={style.errorText}>{formattedError}</span>
          </span>
          {isRunningRequest && (
            <div className={style.nextButtonSpinner}>
              <Spinner borderWidth={rem(3)} color={toRGB(COD_GREY)} />
            </div>
          )}
        </button>
      </span>
    );
  };

  const stepRender = (stepNumber: number, leftContent: () => JSX.Element) => {
    if (!order) {
      return null;
    }
    return (
      <div className={style.stepContainer} style={stepStyle(stepNumber)}>
        <div className={style.stepContainerInner}>
          <div className={style.leftContainer}>
            {leftContent()}
            <div className={style.desktopButtons}>{buttonsRender()}</div>
          </div>
          <div className={style.rightContainer}>
            {!!model && (
              <OrderSummary
                shipping={computeDeliveryMethodPrice(
                  orderRequest.deliveryMethod,
                )}
                order={order.model}
                model={model}
                currentStep={currentStep}
              />
            )}
            {currentStep === 0 && (
              <>
                <div className={style.summaryNotice}>
                  <sup>*</sup>
                  {`${i18n.t("order_summary_notice")}`}
                </div>
              </>
            )}
            <SecurityNotice />
          </div>
          <div className={style.mobileButtons}>{buttonsRender()}</div>
        </div>
      </div>
    );
  };

  const handleProgressClick = (index: number) => {
    if (currentStep === 2) {
      return;
    }

    if (index < currentStep) {
      onPreviousPressed();
      return;
    }

    if (index > currentStep && requestIsValid) {
      onNextPressed();
      return;
    }
  };

  if (!(order && model && collectionName && orderRequest)) {
    return null;
  }

  return (
    <GeneralLayout
      isMenuOrder
      orderStepIndex={currentStep}
      onProgressClick={handleProgressClick}
    >
      <SEO
        path={ROUTES.order()}
        title={`${i18n.t("meta_title_prefix")} - ${pageTitle}`}
      />
      <div className={style.order}>
        <CenterColumn>
          <div className={style.orderProgress}>
            {currentStep !== 2 ? (
              <button
                onClick={onPreviousPressed}
                className={style.orderProgressBack}
              >
                <img
                  src={require("../../../images/icons/back-caret.svg")}
                  alt="back"
                />
              </button>
            ) : (
              <div className={style.orderProgressPlaceholder} />
            )}

            <div className={style.orderProgressCenter}>
              <ProgressMobile
                items={ORDER_STEPS.map(x => i18n.t(x.i18nKey))}
                index={currentStep}
              />
            </div>

            <div className={style.orderProgressPlaceholder} />
          </div>
        </CenterColumn>
        <Header titleDesktop={pageTitle} titleMobile={pageTitle} onlyDesktop />
        {!!errorKey && (
          <div className={style.formError}>{i18n.t(errorKey)}</div>
        )}
        <div className={style.pageContent}>
          <div ref={handleDivRef} />
          {/* INFORMATION */}

          {stepRender(0, () => {
            return (
              <>
                <ContactInformationForm
                  contactInformation={orderRequest}
                  errors={errors}
                  onChange={handleContactInformationChange}
                ></ContactInformationForm>
                <div className={style.shippingContainer}>
                  <AddressForm
                    formKey={"shippingAddress"}
                    address={orderRequest.shippingAddress}
                    title={i18n.t("shipping_address_title")}
                    sameAsShippingAddress={false}
                    errors={errors}
                    onChange={handleAddressChange}
                  ></AddressForm>
                  <DeliveryMethodSelector
                    onChooseDelivery={setSelectedDeliveryMethod}
                    chosenDeliveryMethod={
                      orderRequest.deliveryMethod || DeliveryMethod.PickUp
                    }
                    isDisabled={!shippingAddressIsValid}
                  ></DeliveryMethodSelector>
                </div>
              </>
            );
          })}

          {/* PAYMENT */}
          {stepRender(1, () => {
            return (
              <>
                <CreditCardForm
                  stripe={stripe!}
                  onChange={handleCreditCardChange}
                />
                <AddressForm
                  formKey={"billingAddress"}
                  address={orderRequest.billingAddress}
                  errors={errors}
                  sameAsShippingAddress={!!orderRequest.sameAsShippingAddress}
                  shippingAddress={orderRequest.shippingAddress}
                  title={i18n.t("billing_address_title")}
                  onChange={handleAddressChange}
                ></AddressForm>
              </>
            );
          })}
          {
            <div className={style.stepContainer} style={stepStyle(2)}>
              <div
                className={C(
                  style.stepContainerInner,
                  style.stepContainerInnerNoPaddingMobile,
                )}
              >
                <Confirmation
                  order={order.model}
                  model={model}
                  orderRequest={completedOrderRequest}
                  orderCreatedAt={orderCreatedAt}
                />
              </div>
            </div>
          }
        </div>
      </div>
    </GeneralLayout>
  );
}

export default page(Order);
